define("ember-quickstart/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "foklK/61",
    "block": "[[[1,[28,[35,0],[\"EmberQuickstart\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\"],[1,\"\\n\"],[46,[28,[37,3],null,null],null,null,null]],[],false,[\"page-title\",\"welcome-page\",\"component\",\"-outlet\"]]",
    "moduleName": "ember-quickstart/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});